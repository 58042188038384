import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from './messages';

export default function BtnToggleView({ fullFormView, setFullFormView, setFieldsValue }) {
  const titleIntlKey = fullFormView ? 'hideAdvanceSearch' : 'showAdvanceSearch';

  const handleBtnClick = () => {
    if (!fullFormView) return setFullFormView(true);

    setFullFormView(false);
    // clear form fields
    setFieldsValue(prevState => ({
      ...prevState,
      author: '',
      classification: 'All',
      postedWithin: 'ANYTIME',
      subject: '',
      surnames: ''
    }));
  };

  return (
    <button type="button" className="btn btn--link hide-btn" onClick={handleBtnClick}>
      <FormattedMessage {...m[titleIntlKey]} />
    </button>
  );
}

BtnToggleView.propTypes = {
  fullFormView: PropTypes.bool,
  setFullFormView: PropTypes.func,
  setFieldsValue: PropTypes.func
}