import { defineMessages } from 'react-intl';

export default defineMessages({
  board: 'Board',
  author: 'Author',
  posted: 'Posted',
  attachments: 'Attachments',
  chooseFile: 'Choose file',
  subject: 'Subject',
  surnames: 'Surnames',
  classification: 'Classification',
  message: 'Message',
  messageDesc: 'The text of the message and subject are the property of the poster and cannot be edited.',
  checkbox: 'Send me an alert when anyone replies to this thread.',
  errSize: 'File is to large',
  dltBtnTitle: 'Delete Attachment',
  // === clasification
  ALL: 'All',
  BIBLE: 'Bible',
  BIOGRAPHY: 'Biography',
  BIRTH: 'Birth',
  CEMETERY: 'Cemetery',
  CENSUS: 'Census',
  DEATH: 'Death',
  DEED: 'Deed',
  IMMIGRATION: 'Immigration',
  LOOKUP: 'Lookup',
  MARRIAGE: 'Marriage',
  MILITARY: 'Military',
  OBITUARY: 'Obituary',
  PENSION: 'Pension',
  QUERIES: 'Query',
  WILL: 'Will',
  // !=== clasification
  submit: 'Submit',
  cancel: 'Cancel',
  // for Author edit form
  notYou: '(Not you?)',
  from: 'From',
  surnamePalceholder: 'Only enter the surnames discussed in your post.',
  subjectPalceholder: 'Be specific by including names, places and dates.',
  required: 'Required',
  classificationHelp: 'What is this?',
  saveChanges: 'Save Changes',
  post: 'Post',
  originalMessage: 'Original Message',
  edit: 'Edit',
  preview: 'Preview',
  re: 'Re:'
});
