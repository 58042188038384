import React, {useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import m from './messages';

export function SurnameLettersBoard({ active, letters: levelsLetters }) {
  const ref = useRef();

  useEffect(() => {
    ref.current.children[0].children[0].focus();
  }, []);

  if (!levelsLetters.length) return null;

  return (
    <div className="surname-letters">
      <h3 className="surname-letters__title"><FormattedMessage {...m.surnames} /></h3>
      <p className="surname-letters__desc">
        <FormattedHTMLMessage {...m.tipSurnames} />
      </p>
      <div className="surname-letters__container">
        {levelsLetters.map((levelLetters, index) => (
          <ul className="surname-letters__item" key={index} style={{ paddingLeft: `${index * 10}px` }} ref={ref}>
            {getLettersLink(active, levelLetters)}
          </ul>
        ))}
      </div>
    </div>
  );
}

function getLettersLink(activeLetters, levelLetters) {
  const getClassName = letter => {
    const classValue = 'surname-letters__link';
    if (activeLetters.includes(letter)) return `${classValue} ${classValue}--active`;
    return classValue;
  };

  const getAriaLabel = letter => {
    let ariaLabel = null;
    if (activeLetters.includes(letter)) return ariaLabel = `${letter} is active`;
    return ariaLabel;
  };
  return levelLetters.map(letter => (
    <li key={letter}>
      <Link
        className={getClassName(letter)}
        to={`?sname=${letter}`}
        tabIndex={(activeLetters.length < letter.length) ? '0' : null}
        aria-label={getAriaLabel(letter)}
      >
        {letter}
      </Link>
    </li>
  ));
}

SurnameLettersBoard.defaultProps = {
  active: [],
  levelsLetters: []
};

export default SurnameLettersBoard;
