import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PageModel from './model';
import { spaceless } from "../../../utils/strings";

export function PageLink({ children, classes = '', label = '', history, ...props }) {
  const { model } = PageModel.initProps(props);

  const url = model.toString();
  const className = classNames(spaceless(`pagination-link ${classes}`), {
    disabled: !model.isValid,
    single: model.isSingle
  });

  function onClick(e) {
    const change = model.change(model);
    if (!model.isLink && change) {
      history.push(url);
    } else if (model.isLink && !change) {
      e.preventDefault();
    }
  }

  const ariaProps = {
    ...(!model.isValid  && {
      'aria-disabled': 'true',
      tabIndex: '-1'
    })
  };

  if (model.isLink) {
    return (
      <Link to={url} aria-label={label} className={className} {...ariaProps} onClick={onClick}>
        {children}
      </Link>
    );
  }
  return (
    <button className={className} aria-label={label} {...ariaProps} type="button" onClick={onClick}>
      {children}
    </button>
  );
}

export default withRouter(PageLink);
