import React from 'react';
import { faComments, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';

import m from './messages';
import pageSizes, { getSizes } from '../common/paging/mock';
import PageLinkModel from '../common/old-pagination/model';
import Pagination from '../common/old-pagination';
import { prettyNumber } from '../../utils/strings';
import { convertToDate } from '../utils';

function SurnameResultsBoard(
  {
    description,
    history,
    items,
    newQuery,
    paging
  }) {
  return (
    <section className="surname-result">
      <div className="surname-result__wrap">
        <h3 className="surname-result__title">{description}...</h3>
        <div className="surname-result__content">
          { items.length
            ? getTable(items, paging, history, newQuery)
            : getMessageEmptyResult()
          }
        </div>
      </div>
    </section>
  );
}

function getMessageEmptyResult() {
  return (
    <div className="surname-result__empty">
      <span>
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </span>
      <FormattedMessage {...m.noResults} />
    </div>
  );
}

function getTable(items, paging, history, newQuery) {
  const headerTitles = ['Board', 'Threads', 'Messages', 'LastPost'];

  const query = new PageLinkModel({
    ...paging,
    sizes: getSizes(pageSizes.HUNDRED),
    change: newPage => { history.push(newPage.toString()); },
    params: {
      sname: newQuery.params.sname
    }
  });
  query.path = '/search/surname';

  const getHeaderRow = () => (
    <thead className="surname-result__row surname-result__row--header">
      <tr>
      { headerTitles.map(title => <th className="surname-result__col" key={title}>{title}</th>) }
      </tr>
    </thead>
  );

  const getLink = item => (
    <Link className="surname-result__link" to={`/${item.path}`}>
      <span><FontAwesomeIcon icon={faComments} /></span>
      {item.title}
    </Link>
  );
  const getBodyRow = () => items.map(item => {
    return (
      <tr className="surname-result__row" key={item.path}>
        <th className="surname-result__col">{ getLink(item) }</th>
        <th className="surname-result__col">{prettyNumber(item.threads)}</th>
        <th className="surname-result__col">{prettyNumber(item.messages)}</th>
        <th className="surname-result__col">{convertToDate(item.lastPost)}</th>
      </tr>
    )
  });

  return (
    <>
      <div className="surname-result__paging">
        <Pagination model={query} />
      </div>
      <table className="surname-result__table">
        { getHeaderRow() }
        <tbody>
          { getBodyRow() }
        </tbody>
      </table>
      <div className="surname-result__paging">
        <Pagination model={query} />
      </div>
    </>
  );
}

export default withRouter(SurnameResultsBoard);
